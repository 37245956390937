import { I18n } from "i18n-js";
import translations from "./locales/fr.json";

const i18n = new I18n();

i18n.defaultLocale = "en";
i18n.locale = "fr";
i18n.store(translations);

window.I18n = i18n;
